.tooltip-wrapper {
    padding: 10px;
    background-color: white;
    border-style: solid;
    border-radius: 0.5px;
    border-color: lightgray;
}

.tooltip-label {
    color: #707070;
    font-weight: bold;
    font-size: 15px;
}

.tooltip-rank-label {
    color: #8884d8;
    font-weight: bold;
}

.tooltip-rank-value {
    color: #8884d8;
}

.tooltip-marketcap-label {
    color: #707070;
    font-weight: bold;
}

.tooltip-marketcap-value {
    color: #707070;
}