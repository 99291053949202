.coin-name {
    font-size: 20px;
}

.coin-symbol {
    font-size: 20px;
    color: rgb(80, 80, 80);
    font-weight: bold;
}

.back-button-text {
    font-size: 16px;
    color: rgb(91, 97, 110);
}