// Crypto Billboard main stylesheet

.site-layout {
  min-height: 100vh;

  .site-content {
    .site-page {
      background-color: #fff;
      margin: 24px 16px 0;
      padding: 24px;
      min-height: 360px;
      @media screen and (max-width: 415px) {
        margin-left: 6px;
        margin-right: 6px;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}
