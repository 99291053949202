.blog-button-text {
    font-size: 16px;
    color: rgb(91, 97, 110);
}

.footer-container {
    width: 100%;
    max-width: 1100px;
    margin: auto;
}

.footer-column {
    /* margin-bottom: 16px */
}

.footer-column h2 {
    position: relative;
    margin: 0 auto;
    color: rgb(10, 11, 13);
    font-weight: 600;
    font-size: 16px
}

.footer-item {
    margin: 12px 0;
}

.footer-item a {
    text-decoration: none;
    transition: all .3s;
    font-size: 16px;
    color: rgb(91, 97, 110);
}

.footer-item a:hover {
    color: #40a9ff
}

